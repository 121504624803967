import { ApplicationConfig, importProvidersFrom, isDevMode, provideZoneChangeDetection } from '@angular/core'
import { provideRouter } from '@angular/router'

import { routes } from './app.routes'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { LocationStrategy, PathLocationStrategy } from '@angular/common'
import { appInitializerProviders, httpInterceptorProviders } from '@core'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { FeatherModule } from 'angular-feather'
import { ToastrModule } from 'ngx-toastr'
import { NgProgressHttpModule } from 'ngx-progressbar/http'
import { NgProgressRouterModule } from 'ngx-progressbar/router'
import { allIcons } from 'angular-feather/icons'
import { provideHttpClient } from '@angular/common/http'
import { provideState, provideStore } from '@ngrx/store'
import { provideStoreDevtools } from '@ngrx/store-devtools'

import { provideEffects } from '@ngrx/effects'
import * as profileEffects from './features/profiles/store/effects'
import * as meetingEffects from './features/appointments/store/effects'
import * as taskEffects from './features/tasks/store/effects'
import { BrowserAnimationsModule, provideAnimations, provideNoopAnimations } from '@angular/platform-browser/animations'
import {
  profileCreateFeatureKey,
  profileCreateReducer,
  profileDetailsReducer,
  profileFeatureKey,
  profileReducer,
  profilesFeatureKey,
  profilesReducer,
  profileUpdateFeatureKey,
  profileUpdateReducer,
  profileDetailsFeatureKey,
} from './features/profiles/store/reducers'
import {
  appointmentCreateFeatureKey, appointmentListByCreatorIdFeatureKey, appointmentCreateReducer, appointmentListByCreatorIdReducer
} from './features/appointments/store/reducers'
import { tasksGetListFeatureKey, tasksGetListReducer } from './features/tasks/store/reducers'

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideAnimations(),
    provideNoopAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    httpInterceptorProviders,
    appInitializerProviders,

    provideStore(),
    provideState(profileFeatureKey, profileReducer),
    provideState(profilesFeatureKey, profilesReducer),
    provideState(profileCreateFeatureKey, profileCreateReducer),
    provideState(profileUpdateFeatureKey, profileUpdateReducer),
    provideState(profileDetailsFeatureKey, profileDetailsReducer),
    provideState(appointmentCreateFeatureKey, appointmentCreateReducer),
    provideState(appointmentListByCreatorIdFeatureKey, appointmentListByCreatorIdReducer),
    provideState(tasksGetListFeatureKey, tasksGetListReducer),
    provideEffects(profileEffects, meetingEffects, taskEffects),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      trace: false,
      traceLimit: 75,
    }),
    importProvidersFrom(NgProgressHttpModule, NgProgressRouterModule),
    { provide: MAT_DATE_LOCALE, useValue: 'en-ZA' },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD',
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'YYYY MMM',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'YYYY MMM',
        },
      },
    },
    importProvidersFrom(FeatherModule.pick(allIcons)),
    importProvidersFrom(ToastrModule.forRoot()),
    //provideCharts(withDefaultRegisterables()),
  ],
}
