<div
  class="container-wrap"
  [ngClass]="{
    'sidenav-collapsed': options.sidenavCollapsed && options.navPos !== 'top',
    'navbar-side': options.navPos === 'side',
    'navbar-top': options.navPos === 'top',
    'header-above': options.headerPos === 'above',
    'header-fixed': options.headerPos === 'fixed',
    'footer-fixed': options.footerPos === 'fixed',
    'header-white': options.theme !== 'dark',
  }"
  [dir]="options.dir"
>
  <ng-progress [color]="'#6366f1'"></ng-progress>
  <!-- Header Above -->

  <app-header
    *ngIf="options.showHeader && options.headerPos === 'above'"
    (toggleSidenav)="sidenav.toggle()"
    (toggleSidenavNotice)="toggleSideNavSetIn('notificationSidenav')"
    [showBranding]="true"
  >
  </app-header>

  <mat-sidenav-container class="container" autosize autoFocus>
    <mat-sidenav
      #sidenav
      class="sidenav"
      [mode]="isOver ? 'over' : 'side'"
      [opened]="options.navPos === 'side' && options.sidenavOpened && !isOver"
      (openedChange)="onSidenavOpenedChange($event)"
      (closedStart)="onSidenavClosedStart()"
    >
      <app-sidebar
        [showToggle]="false"
        [showUser]="!!options.showUserPanel"
        [showHeader]="options.headerPos !== 'above'"
        (toggleCollapsed)="toggleCollapsed()"
        [toggleChecked]="!!options.sidenavCollapsed"
        (closeSidenav)="sidenav.close()"
      >
      </app-sidebar>
    </mat-sidenav>
    <mat-sidenav
      [ngSwitch]="toggleSideNavSetOut"
      #rightSideNav
      position="end"
      mode="over"
      opened="false"
      class="customizerSidenav"
    >
      <div *ngSwitchCase="toggleSideNavSetOut">
        <app-customizer (optionsChange)="receiveOptions($event)"></app-customizer>
      </div>
    </mat-sidenav>
    <mat-sidenav-content #content class="content-wrap">
      <app-header
        *ngIf="options.showHeader && options.headerPos !== 'above'"
        [showToggle]="!options.sidenavCollapsed && options.navPos !== 'top'"
        [showBranding]="options.navPos === 'top'"
        (toggleSidenav)="sidenav.toggle()"
        (toggleSidenavNotice)="toggleSideNavSetIn('notificationSidenav')"
      >
      </app-header>
      <app-topmenu *ngIf="options.navPos === 'top'"></app-topmenu>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
