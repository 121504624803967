import { Actions, createEffect, ofType } from '@ngrx/effects'
import { inject } from '@angular/core'
import { catchError, switchMap } from 'rxjs/operators'
import { map, of } from 'rxjs'
import { tasksRequestListAction } from './actions'
import { Task } from '../models/task.models'
import { TasksService } from '../services/tasks.service'

export const meetingCreateEffect = createEffect(
  (actions$ = inject(Actions), tasksService = inject(TasksService)) => {
    return actions$.pipe(
      ofType(tasksRequestListAction.requestTaskList),
      switchMap(({ assigned_to }) => {
        return tasksService.getTasks(assigned_to).pipe(
          map((data: Task[]) => {
            return tasksRequestListAction.requestTaskListSuccess({ tasks: data })
          }),
          catchError((error) => {
            return of(tasksRequestListAction.requestTaskListFailed(error.toString()))
          }),
        )
      }),
    )
  },
  { functional: true },
)
