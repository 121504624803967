import { Injectable } from '@angular/core'
import { PostgrestResponse, PostgrestSingleResponse, SupabaseClient } from '@supabase/supabase-js'
import { SupabaseService } from '@core/services/supabase.service'
import { Observable, Subscriber } from 'rxjs'
import { Appointment, AppointmentCreateDto, AppointmentRoleTypeEnum } from '../models/appointments.models'

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  private supabase!: SupabaseClient

  constructor(private supabaseService: SupabaseService) {
    this.supabase = supabaseService.getClient()
  }

  createAppointment(appointmentDto: AppointmentCreateDto): Observable<Appointment> {
    return new Observable( (subscriber: Subscriber<Appointment>) => {
      const { guests, manager, collaborator, ...appointmentData } = appointmentDto
      const invites = [...guests, manager, collaborator]
      try {
        this.supabase
          .from('appointments')
          .insert(appointmentData)
          .select()
          .then((result: PostgrestResponse<Appointment>) => {
            if(result.data![0].id) {
              this.createAppointmentGuests(invites, result.data![0].id)
              subscriber.next(result.data![0])
            } else {
              subscriber.error('Could not create appointment')
            }
        })
      } catch (e) {
        return subscriber.error('Could not create appointment')
      }
    })
  }

  listByCreatorId(created_by: number): Observable<Appointment[]> {
    return new Observable( (subscriber: Subscriber<Appointment[]>) => {
      try {
        this.supabase
          .from('appointments')
          .select('*, host:created_by(*), appointment_guests(*, guest:guest_id(*))')
          .eq('created_by', created_by)
          .then((result: PostgrestResponse<Appointment>) => {
            if(result.data!.length) {
              subscriber.next(result.data!)
            } else {
              subscriber.error('Could not fetch appointments')
            }
        })
      } catch (e) {
        return subscriber.error('Could not fetch appointments')
      }
    })
  }

  createAppointmentGuests(invite: {id: number, role: AppointmentRoleTypeEnum}[], appointment_id: number): void {
      try {
        this.supabase.from('appointment_guests')
          .insert(invite.map((guest) => ({ appointment_id: appointment_id, guest_id: guest.id, role: guest.role.toUpperCase() })))
          .select()
          .then((result: PostgrestResponse<any>) => {
          if (result.error != null) {
           return false
          }
          return !!result.data?.length;

        })
      } catch (e) {
        throw new Error('Could not create appointment guests')
      }
    }


  // createEmployeeMeetingResponse(
  //   employeeMeetingResponseDto: EmployeeMeetingResponseDto,
  // ): Observable<EmployeeMeetingResponse> {
  //   return new Observable((subscriber: Subscriber<EmployeeMeetingResponse>) => {
  //     try {
  //       this.supabase
  //         .from('employee_meeting_forms')
  //         .insert(employeeMeetingResponseDto)
  //         .select()
  //         .then((result: PostgrestResponse<EmployeeMeetingResponse>) => {
  //           if (result.error != null) {
  //             subscriber.error(result.error.message)
  //           }
  //           if (result.data?.length) {
  //             subscriber.next(result.data![0])
  //           }
  //         })
  //     } catch (e) {
  //       return subscriber.error('Could not store employee response')
  //     }
  //   })
  // }
}
