import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router, RouterLink } from '@angular/router'

import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatButtonModule } from '@angular/material/button'
import { FeatherModule } from 'angular-feather'
import { SupabaseAuthService } from '../../../core/services/supabase-auth.service'
import { AsyncPipe, NgIf } from '@angular/common'
import { Store } from '@ngrx/store'
import { selectProfileState } from '../../../features/profiles/store/selectors'
import { Observable } from 'rxjs'
import { ProfileState } from '../../../features/profiles/models/profiles.model'
import { MatProgressBar } from '@angular/material/progress-bar'
import { MatProgressSpinner } from '@angular/material/progress-spinner'
import { AvatarModule } from 'ngx-avatars'

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatButtonModule,
    RouterLink,
    MatTooltipModule,
    MatIconModule,
    FeatherModule,
    NgIf,
    AsyncPipe,
    MatProgressBar,
    MatProgressSpinner,
    AvatarModule,
  ],
})
export class UserPanelComponent implements OnInit {
  profileState$!: Observable<ProfileState>

  constructor(
    private router: Router,
    private auth: SupabaseAuthService,
    private store: Store<{ profile: ProfileState }>,
  ) {
    this.profileState$ = this.store.select(selectProfileState)
  }

  async ngOnInit() {}

  async logout() {
    await this.auth.signOut()
  }
  public onSelectFile(event: any) {
    console.log('this prints')
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()

      reader.readAsDataURL(event.target.files[0]) // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        // this.url = event.target?.result
      }
    }
  }
}
