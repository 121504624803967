import { Route } from '@angular/router'
import { AdminLayoutComponent } from '@layout/admin-layout/admin-layout.component'
import { AuthLayoutComponent } from '@layout/auth-layout/auth-layout.component'

import { Page403Component } from './features/sessions/page403/page403.component'
import { Page404Component } from './features/sessions/page404/page404.component'
import { Page500Component } from './features/sessions/page500/page500.component'
import { AuthGuard } from './core/guards/auth.guard'

export const routes: Route[] = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/auth', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./features/dashboard/dashboard.routes').then((m) => m.dashboardRoutes),
      },
      {
        path: 'profiles',
        loadChildren: () => import('./features/profiles/profiles.routes').then((m) => m.profilesRoutes),
      },
      {
        path: 'tasks',
        loadChildren: () => import('./features/tasks/tasks.routes').then((m) => m.tasksRoutes),
      },
      {
        path: 'appointments',
        loadChildren: () => import('./features/appointments/appointment.routes').then((m) => m.appointmentRoutes),
      },

      {
        path: '403',
        component: Page403Component,
      },
      {
        path: '404',
        component: Page404Component,
      },
      {
        path: '500',
        component: Page500Component,
      },
    ],
  },

  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/sessions/sessions.routes').then((m) => m.sessionRoutes),
  },
  { path: '**', redirectTo: '404' },
]
