import { Component, ViewEncapsulation } from '@angular/core'
import { MatToolbarModule } from '@angular/material/toolbar'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatToolbarModule],
})
export class FooterComponent {}
