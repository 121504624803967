<div class="user-panel">
  <ng-container *ngIf="profileState$ | async as profileState">
    <mat-progress-spinner *ngIf="profileState.isLoading" color="primary" [diameter]="40" mode="indeterminate">
    </mat-progress-spinner>
    <ng-container *ngIf="!!profileState.profile">
      <label class="hoverable user-panel-block" for="fileInput1">
        <ngx-avatars
          size="70"
          [round]="false"
          name="{{ profileState.profile.first_name }} {{ profileState.profile.last_name }}"
        ></ngx-avatars>
        <div class="hover-text">Upload</div>
        <div class="background"></div>
      </label>
      <input id="fileInput1" type="file" (change)="onSelectFile($event)" />
      <h4 class="user-panel-name">{{ profileState.profile.first_name }} {{ profileState.profile.last_name }}</h4>

      <h5 class="user-panel-email">{{ profileState.profile.email }}</h5>
      <div class="user-panel-icons">
        <button mat-icon-button routerLink="/profile/overview" matTooltip="{{ 'profile' }}">
          <i-feather name="user"></i-feather>
        </button>
        <button mat-icon-button routerLink="/profile/settings" matTooltip="{{ 'edit_profile' }}">
          <i-feather name="edit"></i-feather>
        </button>
        <button mat-icon-button routerLink="/task" matTooltip="{{ 'task' }}">
          <i-feather name="check-circle"></i-feather>
        </button>
        <button mat-icon-button (click)="logout()" matTooltip="{{ 'logout' }}">
          <i-feather name="log-out"></i-feather>
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
