<ng-container>
  <ul class="sidemenu level-1">
    <li class="menu-item" class="menu-item" routerLinkActive="active">
      <a class="menu-heading" [routerLink]="'/dashboard'">Dashboard</a>
    </li>
    <p class="menu-group-heading">Tools</p>
    <li class="menu-item" class="menu-item" routerLinkActive="active">
      <a class="menu-heading" [routerLink]="'/profiles'">Profiles</a>
    </li>
    <li class="menu-item" routerLinkActive="active">
      <a class="menu-heading" [routerLink]="'/appointments'">Appointments</a>
    </li>

  </ul>
</ng-container>
