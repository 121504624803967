import { Actions, createEffect, ofType } from '@ngrx/effects'
import { inject } from '@angular/core'
import { catchError, switchMap } from 'rxjs/operators'
import { map, of } from 'rxjs'
import { AppointmentsService } from '../services/appointments.service'
import { appointmentCreateAction, appointmentListByCreatorIdAction } from './actions'
import { Appointment } from '../models/appointments.models'

export const appointmentCreateEffect = createEffect(
  (actions$ = inject(Actions), appointmentsService = inject(AppointmentsService)) => {
    return actions$.pipe(
      ofType(appointmentCreateAction.requestAppointmentCreate),
      switchMap(({ dto }) => {
        return appointmentsService.createAppointment(dto).pipe(
          map((data: Appointment) => {
            return appointmentCreateAction.requestCreateAppointmentSuccess({ appointment: data })
          }),
          catchError((error) => {
            return of(appointmentCreateAction.requestCreateAppointmentFailed(error.toString()))
          }),
        )
      }),
    )
  },
  { functional: true },
)

export const appointmentListByCreatorIdEffect = createEffect(
  (actions$ = inject(Actions), appointmentsService = inject(AppointmentsService)) => {
    return actions$.pipe(
      ofType(appointmentListByCreatorIdAction.requestAppointmentListByCreatorId),
      switchMap(({ created_by }) => {
        return appointmentsService.listByCreatorId(created_by).pipe(
          map((data: Appointment[]) => {
            return appointmentListByCreatorIdAction.requestAppointmentListByCreatorIdSuccess({ appointments: data })
          }),
          catchError((error) => {
            return of(appointmentListByCreatorIdAction.requestAppointmentListByCreatorIdFailed(error.toString()))
          }),
        )
      }),
    )
  },
  { functional: true },
)

// export const employeeMeetingResponseEffect = createEffect(
//   (actions$ = inject(Actions), meetingService = inject(AppointmentsService)) => {
//     return actions$.pipe(
//       ofType(employeeMeetingResponseAction.requestEmployeeMeetingResponse),
//       switchMap(({ dto }) => {
//         return meetingService.createEmployeeMeetingResponse(dto).pipe(
//           map((data: EmployeeMeetingResponse) => {
//             return employeeMeetingResponseAction.requestEmployeeMeetingResponseSuccess({ employeeMeeting: data })
//           }),
//           catchError((error) => {
//             return of(employeeMeetingResponseAction.requestEmployeeMeetingResponseFailed(error.toString()))
//           }),
//         )
//       }),
//     )
//   },
//   { functional: true },
// )
