import { Injectable } from '@angular/core'
import { PostgrestResponse, SupabaseClient } from '@supabase/supabase-js'
import { SupabaseService } from '@core/services/supabase.service'
import { Observable, Subscriber } from 'rxjs'
import { Profile } from '../../profiles/models/profiles.model'
import { Task } from '../models/task.models'

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private supabase!: SupabaseClient

  constructor(private supabaseService: SupabaseService) {
    this.supabase = supabaseService.getClient()
  }

  getTasks(assigned_to: number): Observable<Task[]> {
    return new Observable((subscriber: Subscriber<Task[]>) => {
      try {
        this.supabase
          .from('tasks')
          .select(`*, assigned_to(*), assigned_by(*)`)
          .eq('assigned_to', assigned_to)
          .then((result: PostgrestResponse<Task>) => subscriber.next(result.data!))
      } catch (error) {
        subscriber.error(error)
      }
    })
  }
}
