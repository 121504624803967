import { CommonModule, isPlatformBrowser } from '@angular/common'
import { AfterViewInit, Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core'
import { RouterModule } from '@angular/router'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterModule],
  providers: [],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const loader = this.renderer.selectRootElement('.loader')
      if (loader.style.display != 'none') {
        loader.style.display = 'none'
      }
    }
  }
}
