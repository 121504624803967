import { Actions, createEffect, ofType } from '@ngrx/effects'
import { inject } from '@angular/core'
import { ProfileService } from '../service/profile.service'
import {
  profileActions,
  profileCreateActions,
  profileDetailsActions,
  profilesActions,
  profileUpdateActions,
} from './actions'
import { Profile, ProfileDto } from '../models/profiles.model'
import { catchError, switchMap } from 'rxjs/operators'
import { map, of } from 'rxjs'

export const profileEffect = createEffect(
  (actions$ = inject(Actions), profileService = inject(ProfileService)) => {
    return actions$.pipe(
      ofType(profileActions.requestByUid),
      switchMap(({ uid }) => {
        return profileService.profile(uid).pipe(
          map((data: Profile) => {
            return profileActions.profileByUidSuccess({ profile: data })
          }),
          catchError((error) => {
            return of(profileActions.profileByUidFailed(error))
          }),
        )
      }),
    )
  },
  { functional: true },
)

export const profileDetailsByIdEffect = createEffect(
  (actions$ = inject(Actions), profileService = inject(ProfileService)) => {
    return actions$.pipe(
      ofType(profileDetailsActions.requestProfileById),
      switchMap(({ id }) => {
        return profileService.profileDetails(id).pipe(
          map((data: Profile) => {
            return profileDetailsActions.requestProfileByIdSuccess({ profile: data })
          }),
          catchError((error) => {
            return of(profileDetailsActions.requestProfileByIdFailed(error))
          }),
        )
      }),
    )
  },
  { functional: true },
)

export const profilesEffect = createEffect(
  (actions$ = inject(Actions), profileService = inject(ProfileService)) => {
    return actions$.pipe(
      ofType(profilesActions.requestProfiles),
      switchMap(() => {
        return profileService.profiles().pipe(
          map((data: Profile[]) => {
            return profilesActions.requestProfilesSuccess({ profiles: data })
          }),
          catchError((error) => {
            return of(profilesActions.requestProfilesFailed(error))
          }),
        )
      }),
    )
  },
  { functional: true },
)

export const profilesCreateEffect = createEffect(
  (actions$ = inject(Actions), profileService = inject(ProfileService)) => {
    return actions$.pipe(
      ofType(profileCreateActions.requestCreateProfile),
      switchMap(({ dto }) => {
        return profileService.createProfile(dto).pipe(
          map((data: Profile) => {
            return profileCreateActions.requestCreateProfileSuccess({ profile: data })
          }),
          catchError((error) => {
            return of(profileCreateActions.requestCreateProfileFailed(error.toString()))
          }),
        )
      }),
    )
  },
  { functional: true },
)

export const profilesUpdateEffect = createEffect(
  (actions$ = inject(Actions), profileService = inject(ProfileService)) => {
    return actions$.pipe(
      ofType(profileUpdateActions.requestUpdateProfile),
      switchMap(({ dto }) => {
        return profileService.updateProfile(dto).pipe(
          map(() => {
            return profileUpdateActions.requestUpdateProfileSuccess({ success: true })
          }),
          catchError((error) => {
            return of(profileUpdateActions.requestUpdateProfileFailed(error.toString()))
          }),
        )
      }),
    )
  },
  { functional: true },
)
